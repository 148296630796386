<template lang="pug">
v-container.extra-gruppencode(flex grid-list-md style="max-width: 900px")
  .mb-3.title(ref="top") Willkommen zur Anmeldung für die JugendFEIER {{termin && strftime(termin.zeit, "Y")}}
  template(v-if="loggedIn")
    | Sie sind bereits eingeloggt

  template(v-else-if="$apollo.queries.termine.loading")
    v-progress-linear(:active="$apollo.queries.termine.loading" indeterminate)

  template(v-else-if="success")
    .text-xs-center(v-if="success")
      .display-1.pb-2 FAST GESCHAFFT!
      .display-1.pb-2 Für die nächsten 60 Minuten {{editTns.length === '1' ? 'ist 1 Platz' : `sind ${editTns.length} Plätze`}} für Sie reserviert.
      .title  Um die Anmeldung zur JugendFeier abzuschließen, schauen Sie bitte in die E-Mail, die wir Ihnen soeben an:
      .display-1.py-2 {{edit.email}}
      .title  geschickt haben.

  template(v-else-if="termin && edit")
    v-alert.mb-3(:value="gqlHasErrorsFor('userWithGruppencodeCreate')" dismissible @input="gqlClearErrorsFor('userWithGruppencodeCreate')")
      .subheading Bitte überprüfen Sie ihre Eingaben.
      template(v-for="error in gqlErrorsFor('userWithGruppencodeCreate', 'base')") {{error}}<br>

    p Sie können in diesem Bereich als Erziehungsberechtigter Ihr Kind zu der gewählten Veranstaltung hinzubuchen. Dazu benötigen wir nur einige Daten. Diese werden für die verbindliche Anmeldung verwendet. Für weitere Rückfragen stehen wir Ihnen gerne unter {{constants && constants.infoPhone}} zur Verfügung.
    .ml-5
      p <strong>Termin:</strong> {{strftime(termin.zeit, 'dddd D. MMM Y H:mm [Uhr]')}} {{termin.ort.adverbial}} {{termin.ort.name}}
      p: strong Gruppe: {{gruppe.jid}} - {{gruppe.vollerName}}
      p
        strong Preise:<br>
        | JugendFEIER-Teilnahme (Vorbereitungsprogramm und Festveranstaltung) pro Jugendliche_r:<br> {{termin.ticketpreis.teilnehmer}} €{{termin.ticketpreis.sozialRabatt > 0 ? `, Sozialrabatt ${termin.ticketpreis.sozialRabatt} €` : ''}}{{termin.ticketpreis.fruehbucherRabatt > 0 && dateIsInFuture(termin.fruehbucherDatum) ? `, Frühbucherrabatt ${termin.ticketpreis.fruehbucherRabatt} €` : ''}}<br>
        | Gästekarten für die Festveranstaltung (Bestellung im Frühjahr des Feierjahres):<br> {{termin.ticketpreis.gast}} €, Kinder bis einschließlich 13 Jahre {{termin.ticketpreis.gastKind}} €,
        template(v-if="termin.ticketpreis.streamer")
          |  Streamer_in {{termin.ticketpreis.streamer}} €,
        |  erwachsene Mitglieder des HVD kostenlos<br>

    .mt-3.title Bitte geben Sie hier Ihre Vertragsdaten an
    v-layout(wrap)
      v-flex(xs12 sm6): v-text-field(label="E-Mail *" autocomplete="username" v-model="edit.email" :error-messages="gqlErrorsFor('userWithGruppencodeCreate', 'email')" persistent-hint hint="Bitte schreiben Sie ALLE Buchstaben Ihrer E-Mail Adresse KLEIN!")
      v-flex(xs12 sm6): v-text-field(label="E-Mail Bestätigung *" autocomplete="username" v-model="edit.emailConfirmation" :error-messages="gqlErrorsFor('userWithGruppencodeCreate', 'emailConfirmation')")
      v-flex(xs12 sm6): v-text-field(label="Passwort *" autocomplete="new-password" v-model="edit.password" type="password" :error-messages="gqlErrorsFor('userWithGruppencodeCreate', 'password')" persistent-hint hint="mindestens 8 zeichen")
      v-flex(xs12 sm6): v-text-field(label="Passwort Bestätigung *" autocomplete="new-password" v-model="edit.passwordConfirmation" type="password" :error-messages="gqlErrorsFor('userWithGruppencodeCreate', 'passwordConfirmation')")
      v-flex(xs12 sm6): v-text-field(label="Vorname (Vertragspartner)*" v-model="edit.vorname" :error-messages="gqlErrorsFor('userWithGruppencodeCreate', 'vorname')")
      v-flex(xs12 sm6): v-text-field(label="Nachname (Vertragspartner)*" v-model="edit.nachname" :error-messages="gqlErrorsFor('userWithGruppencodeCreate', 'nachname')")
      v-flex(xs12 sm6): v-text-field(label="Telefonnummer *" hint="(mobil/tagsüber)" persistent-hint v-model="edit.telefon" :error-messages="gqlErrorsFor('userWithGruppencodeCreate', 'telefon')")
      v-flex(xs12 sm6): v-text-field(label="Adresszusatz" v-model="edit.adresszusatz" :error-messages="gqlErrorsFor('userWithGruppencodeCreate', 'adresszusatz')" hint="(z. B. c/o, abweichender Name am Briefkasten oder andere zur Zustellung notwendige Angabe)" persistent-hint)
      v-flex(xs12 sm6): v-text-field(label="Straße *" v-model="edit.strasse" :error-messages="gqlErrorsFor('userWithGruppencodeCreate', 'strasse')")
      v-flex(xs12 sm6): v-text-field(label="Hausnummer *" v-model="edit.strasseNr" :error-messages="gqlErrorsFor('userWithGruppencodeCreate', 'strasseNr')")
      v-flex(xs12 sm6): v-text-field(label="PLZ *" v-model="edit.plz" :error-messages="gqlErrorsFor('userWithGruppencodeCreate', 'plz')")
      v-flex(xs12 sm6): v-text-field(label="Ort *" v-model="edit.ort" :error-messages="gqlErrorsFor('userWithGruppencodeCreate', 'ort')")
      v-flex(xs12 v-if="termin.ticketpreis.sozialRabatt")
        v-checkbox(label="Ich erhalte ALG II oder ich und / oder mein Kind besitze/n den Berlinpass und möchte/n den Sozialrabatt in Anspruch nehmen." v-model="edit.sozialRabatt" :error-messages="gqlErrorsFor('userWithGruppencodeCreate', 'sozialRabatt')")
        template(v-if="edit.sozialRabatt")
          v-radio-group.mt-0(v-model="edit.sozialausweisKommt" :error-messages="gqlErrorsFor('userWithGruppencodeCreate', 'sozialausweisKommt')")
            v-radio.mb-0(label="Den entsprechenden Nachweis lade ich hoch." value="upload")
            v-radio.mb-0(label="Den entsprechenden Nachweis sende ich Ihnen innerhalb von 7 Tagen per Post, Fax oder E-Mail." value="later")
          p(v-if="edit.sozialausweisKommt === 'upload'")
            file-input(v-model="edit.sozialausweisFileName" base64 @base64="edit.sozialausweisFile = $event" :error-messages="gqlErrorsFor('userWithGruppencodeCreate', 'sozialausweisFile').concat(gqlErrorsFor('userWithGruppencodeCreate', 'sozialausweisFileName')).concat(gqlErrorsFor('userWithGruppencodeCreate', 'sozialausweis'))")
          p(v-if="edit.sozialausweisKommt === 'later'")
            | Postadresse: {{constants && constants.infoPost}}<br>
            | Fax: {{constants && constants.infoFax}}, E-Mail: {{constants && constants.infoMail}}

    .my-3.title Angaben zu Ihrem Kind / Ihren Kindern
    v-layout(wrap)
      template(v-for="tn, tnI in editTns")
        v-flex(xs12): strong  {{strftime(termin.zeit, 'dddd D. MMM Y H:mm [Uhr]')}} {{termin.ort.adverbial}} {{termin.ort.name}}
        v-flex(xs12): .error--text {{!editTn ? '' : gqlErrorsFor('userWithGruppencodeCreate', tnI, 'base').join(", ")}}
        v-flex(xs12 v-if="tn.gruppe") Gruppe: {{tn.gruppe.jid}} {{tn.gruppe.vollerName}}
        v-flex(xs12 sm6): v-text-field(label="Vorname der_s Jugendlichen *" v-model="tn.vorname" :error-messages="!editTn ? [] : gqlErrorsFor('userWithGruppencodeCreate', tnI, 'teilnehmer_vorname')" :disabled="tn.gebucht")
        v-flex(xs12 sm6): v-text-field(label="Nachname der_s Jugendlichen *" v-model="tn.nachname" :error-messages="!editTn ? [] : gqlErrorsFor('userWithGruppencodeCreate', tnI, 'teilnehmer_nachname')" :disabled="tn.gebucht")
        v-flex(xs12 sm6): radio-group(label="Geschlecht *" row :items="geschlechter" v-model="tn.geschlecht" :error-messages="!editTn ? [] : gqlErrorsFor('userWithGruppencodeCreate', tnI, 'geschlecht')" :disabled="tn.gebucht")
        v-flex(xs12 sm6): input-date(label="Geburtstag *" v-model="tn.geburtstag" :error-messages="!editTn ? [] : gqlErrorsFor('userWithGruppencodeCreate', tnI, 'geburtstag')" :disabled="tn.gebucht")
        v-flex(xs12 sm6): v-text-field(label="Gfls. Behinderung" v-model="tn.behinderung" :error-messages="!editTn ? [] : gqlErrorsFor('userWithGruppencodeCreate', tnI, 'behinderung')" :disabled="tn.gebucht")
        v-flex(xs12 sm6): v-text-field(label="E-Mail-Adresse der_s Jugendlichen" v-model="tn.email" :error-messages="!editTn ? [] : gqlErrorsFor('userWithGruppencodeCreate', tnI, 'teilnehmer_email')" :disabled="tn.gebucht" hint="(Diese E-Mail-Adresse nutzen wir ausschließlich, um Ihr Kind auf Angebote im Vorbereitungsprogramm direkt aufmerksam machen zu können.)" persistent-hint)
        v-flex(xs12): v-checkbox(label="Gegebenenfalls abweichende Adresse der_s Jugendliche_n" v-model="tn.hatAdresse" :disabled="tn.gebucht")
        template(v-if="tn.hatAdresse")
          v-flex(xs12): v-text-field(label="Adresszusatz" v-model="tn.adresszusatz" :error-messages="!editTn ? [] : gqlErrorsFor('userWithGruppencodeCreate', tnI, 'tn_adresszusatz')" :disabled="tn.gebucht"  hint="(z. B. c/o, abweichender Name am Briefkasten oder andere zur Zustellung notwendige Angabe)" persistent-hint)
          v-flex(xs12 sm6): v-text-field(label="Straße" v-model="tn.strasse" :error-messages="!editTn ? [] : gqlErrorsFor('userWithGruppencodeCreate', tnI, 'tn_strasse')" :disabled="tn.gebucht")
          v-flex(xs12 sm6): v-text-field(label="Hausnummer" v-model="tn.hausnummer" :error-messages="!editTn ? [] : gqlErrorsFor('userWithGruppencodeCreate', tnI, 'tn_hausnummer')" :disabled="tn.gebucht")
          v-flex(xs12 sm6): v-text-field(label="PLZ" v-model="tn.plz" :error-messages="!editTn ? [] : gqlErrorsFor('userWithGruppencodeCreate', tnI, 'tn_plz')" :disabled="tn.gebucht")
          v-flex(xs12 sm6): v-text-field(label="Ort" v-model="tn.ort" :error-messages="!editTn ? [] : gqlErrorsFor('userWithGruppencodeCreate', tnI, 'tn_ort')" :disabled="tn.gebucht")
        v-flex(xs12 v-if="termin && termin.ort.juhu"): v-checkbox(label="Juhu-Mitglied werden" v-model="tn.werdeJuHuMitglied" :error-messages="!editTn ? [] : gqlErrorsFor('userWithGruppencodeCreate', tnI, 'werde_hvd_mitglied')" :disabled="tn.gebucht"): div(slot="label")
          div(v-html="constants && constants.werdeJuhuHtml || 'werde JuHu (...Lade)'")
        v-btn(@click="removeTeilnehmer(tnI)" v-if="tnI > 0")
          v-icon(left) remove
          | eigenes Kind
        v-flex(xs12): v-divider

      v-flex(xs12)
        | Zwillinge?
        v-btn(@click="addTeilnehmer(termin)")
          v-icon(left) add
          | eigenes Kind

      v-flex(xs12): accept-fotos(v-model="edit.acceptPhotoVideo" :error-messages="gqlErrorsFor('userWithGruppencodeCreate', 'acceptPhotoVideo')")
      v-flex(xs12): accept-agb(v-model="edit.acceptAgbs" :error-messages="gqlErrorsFor('userWithGruppencodeCreate', 'acceptAgbs')")
      v-flex(xs12): accept-datenschutz(v-model="edit.acceptDatenschutz" :error-messages="gqlErrorsFor('userWithGruppencodeCreate', 'acceptDatenschutz')")
      v-flex(xs12): accept-newsletter(v-model="edit.acceptInfoEmails" :error-messages="gqlErrorsFor('userWithGruppencodeCreate', 'acceptInfoEmails')")
      v-flex(xs12): bekannt-von(v-model="edit.bekanntVon" :sonstiges.sync="edit.bekanntVonSonstiges" :error-messages="gqlErrorsFor('userWithGruppencodeCreate', 'bekanntVon')")

      p.mt-4(v-if="!user.komplett || editTns.length"): v-btn(color="primary" @click="save" :loading="loading > 0") Speichern
  template(v-else)
    .title Ungültiger Gruppencode

</template>

<style lang="stylus" scoped>
.extra-gruppencode
  >>> label
    font-weight normal

</style>



<script>
import graphqlErrorObject from '../../../backend/mixins/graphql-error-object.js'
import gql from 'gql-id.macro'
import moment from 'moment'

export default {
  props: {
    data: Object
  },
  mixins: [graphqlErrorObject],
  data() {
    return {
      user: {},
      edit: {
        email: '',
        emailConfirmation: '',
        password: '',
        passwordConfirmation: '',
        telefon: '',
        plaetze: '',
        constants: null,
        selbeKlasse: null,
        schuleId: null,
        gruppenname: '',
        photoAccepted: false,
        agbAccepted: false,
        datenschutzAccepted: false,
        newsletterAccepted: false,
        sozialRabatt: false,
        sozialausweisFileName: '',
        sozialausweisFile: null,
        sozialausweisKommt: null,
      },
      geschlechter: [
        { text: 'Weiblich', value: 'female' },
        { text: 'Männlich', value: 'male' },
        { text: 'Divers', value: 'not_specified' },
      ],
      editTn: 1,
      success: false,
      loading: 0,
      termine: [],
      editTns: [],
    }
  },
  apollo: {
    gruppen: {
      query: gql`query extraGruppencodeVue($code: String!) {
        gruppen(where: { code: $code }, role: ANON) {
          id
          jid
          vollerName
          termin {
            id
            zeit
            fruehbucherDatum
            ort {
              id
              adverbial
              name
              juhu
            }
            ticketpreis {
              id
              teilnehmer
              sozialRabatt
              fruehbucherRabatt
              gast
              gastKind
              streamer
            }
          }
        }
        constants {
          werdeJuhuHtml
          werdeJuhuDefault
          infoMail
          infoPhone
          infoPost
          infoFax
        }
      }`,
      update(response) {
        this.constants = response.constants // must be loaded before termin data to ensure default option is honored
        return response.gruppen
      },
      variables() {
        return {
          code: this.code
        }
      },
      skip() {
        return !this.code
      }
    },
    termine: {
      query: gql`query extraTermine($regionJahr: [String!], $q: String) {
        termine(q: $q, where: {kategorieIn: $regionJahr}, role: ANON) {
          id
          zeit
          jahr
          fruehbucherDatum
          tnFreiDisplay
          tnFreiDisplayMehr
          tnReserviertDisplay
          anmeldungGeschlossen
          anmeldungOffen
          anmeldeschlussInfoWithDefault
          infotextWithDefault
          zeigeWarteliste
          zeigeWartelisteAb
          ort {
            id
            name
            adverbial
            kuerzel
            region
          }
        }
      }`,
      variables() {
        return {
          q: JSON.stringify({ sorts: ["zeit", "ort_name"], sichtbare: true, kommende: true }),
          regionJahr: this.data && this.data.regionjahr
        }
      },
      skip() {
        return !this.data
      }
    },
  },
  watch: {
    termin(termin) {
      if (termin) {
        this.addTeilnehmer(termin)
        const edit = {}
        const user = {}
        edit.email = user.email || ''
        edit.nachname = user.nachname || ''
        edit.vorname = user.vorname || ''
        edit.telefon = user.telefon || ''
        edit.strasse = user.strasse || ''
        edit.strasseNr = user.strasseNr || ''
        edit.adresszusatz = user.adresszusatz || ''
        edit.plz = user.plz || ''
        edit.ort = user.ort || ''
        edit.acceptAgbs = user.acceptAgbs || false
        edit.acceptDatenschutz = user.acceptDatenschutz || false
        edit.acceptPhotoVideo = user.acceptPhotoVideo || false
        edit.acceptInfoEmails = user.acceptInfoEmails || false
        edit.bekanntVon = user.bekanntVon || []
        edit.bekanntVonSonstiges = null // user.bekanntVonSonstiges || null
        this.edit = edit
      }
    }
  },
  computed: {
    code() {
      return this.data && this.data.content && this.data.content.code
    },
    gruppe() {
      return this.gruppen && this.gruppen[0]
    },
    termin() {
      return this.gruppe && this.gruppe.termin
    },
    loggedIn() {
      return this.$store.state.loggedIn
    },
  },
  methods: {
    addTeilnehmer(termin) {
      this.editTns.push({
        vorname: '',
        nachname: '',
        geschlecht: null,
        geburtstag: null,
        behinderung: '',
        email: '',
        werdeJuHuMitglied: termin.ort.juhu ? this.constants.werdeJuhuDefault : null,
        adresszusatz: '',
        strasse: '',
        hausnummer: '',
        plz: '',
        ort: '',
        hatAdresse: false,
        schuleId: null,
        gruppenname: '',
      })
    },
    removeTeilnehmer(tnI) {
      this.editTns.splice(tnI, 1)
    },
    strftime(date, format) {
      if (date) {
        return moment(date).format(format)
      } else {
        return ''
      }
    },
    dateIsInFuture(date) {
      return new Date(date || '1970-1-1').getTime() > Date.now()
    },
    save() {
      this.loading++
      this.gqlClearErrorsFor('userWithGruppencodeCreate')
      this.$apollo.mutate({
        mutation: gql`mutation ExtraGruppencodeVueSave(
          $gruppencode: String,

          $email: String,
          $emailConfirmation: String,
          $password: String,
          $passwordConfirmation: String,

          $nachname: String,
          $vorname: String,
          $telefon: String,
          $strasse: String,
          $strasseNr: String,
          $adresszusatz: String,
          $plz: String,
          $ort: String,

          $sozialRabatt: Boolean,
          $sozialausweisFileName: String,
          $sozialausweisFile: String,
          $sozialausweisKommt: String,

          $acceptAgbs: Boolean,
          $acceptDatenschutz: Boolean,
          $acceptPhotoVideo: Boolean,
          $acceptInfoEmails: Boolean,
          $bekanntVon: [String],
          $bekanntVonSonstiges: String,

          $teilnehmer: [UserWithGruppencodeCreateInput],
        ) {
          userWithGruppencodeCreate(
            gruppencode: $gruppencode,

            email: $email,
            emailConfirmation: $emailConfirmation,
            password: $password,
            passwordConfirmation: $passwordConfirmation,
            
            sozialRabatt: $sozialRabatt,
            sozialausweisFileName: $sozialausweisFileName,
            sozialausweisFile: $sozialausweisFile,
            sozialausweisKommt: $sozialausweisKommt,

            nachname: $nachname,
            vorname: $vorname,
            telefon: $telefon,
            strasse: $strasse,
            strasseNr: $strasseNr,
            adresszusatz: $adresszusatz,
            plz: $plz,
            ort: $ort,
            acceptAgbs: $acceptAgbs,
            acceptDatenschutz: $acceptDatenschutz,
            acceptPhotoVideo: $acceptPhotoVideo,
            acceptInfoEmails: $acceptInfoEmails,
            bekanntVon: $bekanntVon,
            bekanntVonSonstiges: $bekanntVonSonstiges,

            teilnehmer: $teilnehmer,
          ) {
            success
          }
        }`,
        variables: {
          ...this.edit,
          teilnehmer: this.editTns.map(tn => ({...tn, termin: undefined, gruppe: undefined })),
          gruppencode: this.code,
        }
      }).then(response => {
        this.loading--
        if (response.data.userWithGruppencodeCreate.success) {
          this.success = true
        }
      }, err => {
        this.gqlAddErrors(err, false, ['userWithGruppencodeCreate', 'base'])
        this.$vuetify.goTo(this.$refs.top)
        this.loading--
      })
    },
  }
}
</script>
