<template lang="pug">
v-container.extra-termine.pb-0(flex grid-list-md ref="top" id="extra-termine-top")
  .text-xs-center(v-if="success")
      .display-1.pb-2(v-if="warteliste || isVoranmeldung") FAST GESCHAFFT!
      .display-1.pb-2(v-else) Für die nächsten 60 Minuten {{edit.plaetze === '1' ? 'ist 1 Platz' : `sind ${edit.plaetze} Plätze`}} für Sie reserviert.
      .title  Um die Anmeldung zur JugendFEIER{{warteliste ? '-Warteliste' : ''}} abzuschließen, schauen Sie bitte in die E-Mail, die wir Ihnen soeben an:
      .display-1.py-2 {{edit.email}}
      .title  geschickt haben.

  template(v-else)
    template(v-if="termin")
      h2.headline JugendFEIER {{strftime(termin.zeit, 'Y')}} {{termin.ort.adverbial}} {{termin.ort.name}}
      | Dein JugendFEIER-Termin:
      strong  {{strftime(termin.zeit, 'dddd, D. MMM Y H:mm [Uhr]')}}
    template(v-else-if="ort")
      h2.headline JugendFEIER {{$route.query.jahr}} {{ort.adverbial}} {{ort.name}}
    template(v-else)
      v-layout(wrap)
        v-flex(xs12): p.title Wo möchten Sie feiern?
        template(v-for="regionOrte in regionen")
          template(v-for="ortJahre in regionOrte.orte")
            v-flex(xs12)
              v-divider
              span.subheading {{ortJahre.ort.name}}
              template(v-for="jahrTermine in ortJahre.jahre")
                v-btn(outline color="primary" @click="queryFilterUpdates({ ort: ortJahre.ort.id, jahr: jahrTermine.jahr }, { selector: '#extra-termine-top' }, { push: true, keepPage: true })") {{jahrTermine.jahr}}

    template(v-if="ort && !isVoranmeldung && !warteliste && !termin")
      v-layout(wrap align-center)
        template(v-for="termin in ortTermine")
          v-flex(xs12 md5)
            v-layout(wrap)
              v-flex(xs12 md4)
                | {{strftime(termin.zeit, 'dddd')}}
              v-flex(xs12 md4)
                | {{strftime(termin.zeit, 'D. MMM Y')}}
              v-flex(xs12 md4)
                | {{strftime(termin.zeit, 'H:mm [Uhr]')}}
          v-flex(xs12 md7)
            template(v-if="loggedIn && !fixme")
            template(v-else-if="termin.anmeldungOffen")
              template(v-if="termin.tnFreiDisplay > 0")
                span.text-success.neon(v-if="termin.tnFreiDisplayMehr")  Plätze frei
                span.text-warning.neon(v-else)  {{termin.tnFreiDisplay}} Plätze frei
                v-btn(@click="queryFilterUpdates({ anmeldungTermin: termin.id }, { selector: '#extra-termine-top' }, { push: true, keepPage: true })" color="primary") Anmelden
              template(v-else)
                span.text-danger.neon Keine Plätze frei
                template(v-if="termin.tnReserviertDisplay")
                  v-menu(offset-y)
                    v-btn(slot="activator") Infos
                    v-card(max-width="500px"): v-card-text {{alleFeiernReserviertText}}
            template(v-else)
              v-menu(offset-y)
                v-btn(slot="activator") Infos
                v-card(max-width="500px"): v-card-text
                  div(v-if="termin.anmeldungGeschlossen" v-html="termin.anmeldeschlussInfoWithDefault")
                  div(v-else v-html="termin.infotextWithDefault")

        v-flex.pt-4(xs12 v-if="zeigeWarteliste && (!loggedIn || fixme)")
          | Nicht mehr genug Plätze frei? Trag dich in unsere &#32;
          v-btn(@click="queryFilterUpdates({ anmeldungWarteliste: true }, { selector: '#extra-termine-top' }, { push: true, keepPage: true })") Warteliste
          |  ein.

    template(v-else-if="ort && (isVoranmeldung || warteliste || termin)")
      template(v-if="loggedIn && !fixme")
        p Anmeldung nicht möglich da eingeloggt.
      template(v-else-if="termin && !termin.anmeldungOffen")
        p Anmeldung für diesen Feiertermin is im Moment geschlossen.
      template(v-else)
        v-layout(wrap)
          v-flex(xs12)
            p
              | Sie wollen Ihr eigenes Kind bzw. Ihre eigenen Kinder anmelden? Dann nutzen Sie bitte die &#32;
              a(@click="edit.einzelanmeldung = true") Einzelanmeldung.
            p
              | Sie wollen eine Gruppe von Jugendlichen anmelden (zu denen auch Ihr Kind bzw. Ihre Kinder gehören)? Zum Beispiel eine Klasse oder eine Gruppe befreundeter oder verwandter Kinder? Dann nutzen Sie bitte die &#32;
              a(@click="edit.einzelanmeldung = false") Gruppenanmeldung.
            radio-group(label="" v-model="edit.einzelanmeldung" row :items="[{ text: 'Einzelanmeldung', value: true }, { text: 'Gruppenanmeldung', value: false }]" :error-messages="gqlErrorsFor('userWithReservierungCreate', 'einzelanmeldung')")
          v-flex(xs12 v-if="edit.einzelanmeldung === false"): radio-group(label="Die Jugendliche_n besuchen dieselbe Klasse *" row v-model="edit.selbeKlasse" :error-messages="gqlErrorsFor('userWithReservierungCreate', 'selbeKlasse')" :items="[{ text: 'Ja', value: true }, { text: 'Nein', value: false }]")
          template(v-if="edit.selbeKlasse !== null")
            v-flex(xs12 sm6 v-if="edit.selbeKlasse"): select-schule(label="Schule *" other v-model="edit.schuleId" :error-messages="gqlErrorsFor('userWithReservierungCreate', 'schuleId')")
            v-flex(xs12 sm6 v-if="!edit.einzelanmeldung || edit.selbeKlasse"): v-text-field(:label="edit.selbeKlasse ? edit.schuleId === '0' ? 'Schule und Klasse *' : 'Klasse *' : 'Gruppenname *'" v-model="edit.gruppenname" :error-messages="gqlErrorsFor('userWithReservierungCreate', 'gruppenname')")

        v-layout(wrap)
          v-flex(xs12 sm6): v-text-field(label="Anzahl der Jugendlichen *" v-model="edit.plaetze" :error-messages="gqlErrorsFor('userWithReservierungCreate', 'plaetze')")
          v-flex(xs12 sm6): v-text-field(label="Telefonnummer *" v-model="edit.telefon" hint="mobil/tagsüber" persistent-hint required :error-messages="gqlErrorsFor('userWithReservierungCreate', 'telefon')")
          template(v-if="loggedIn")
            v-flex(xs12 sm6): v-text-field(label="E-Mail *" autocomplete="username" disabled :value="currentUser && currentUser.email")
          template(v-else)
            v-flex(xs12 sm6): v-text-field(label="E-Mail *" autocomplete="username" v-model="edit.email" @blur="edit.email = edit.email.toLowerCase()" :error-messages="gqlErrorsFor('userWithReservierungCreate', 'email')" persistent-hint)
            v-flex(xs12 sm6): v-text-field(label="E-Mail Bestätigung *" autocomplete="username" v-model="edit.emailConfirmation" :error-messages="gqlErrorsFor('userWithReservierungCreate', 'emailConfirmation')")
            v-flex(xs12 sm6): v-text-field(label="Passwort *" autocomplete="new-password" v-model="edit.password" type="password" :error-messages="gqlErrorsFor('userWithReservierungCreate', 'password')" persistent-hint hint="mindestens 8 zeichen")
            v-flex(xs12 sm6): v-text-field(label="Passwort Bestätigung *" autocomplete="new-password" v-model="edit.passwordConfirmation" type="password" :error-messages="gqlErrorsFor('userWithReservierungCreate', 'passwordConfirmation')")

          v-flex(xs12): .py-2
            template(v-for="err in [gqlErrorsFor('userWithReservierungCreate', 'termin').concat(gqlErrorsFor('userWithReservierungCreate', 'wartelistenTermine')).join(', ') || '']")
              template(v-if="ort && (isVoranmeldung || warteliste)")
                | An diesen Tagen kann ich <strong>NICHT</strong>
                v-layout(wrap)
                  template(v-for="tag in ortTage")
                    v-flex: v-checkbox.d-inline-block.mr-2(v-model="selectedTage" :value="tag.value" :label="tag.text" hide-details :error="!!err")
              .error--text {{err}}

          v-flex(xs12)
            accept-datenschutz(v-model="edit.datenschutzAccepted" :error-messages="gqlErrorsFor('userWithReservierungCreate', 'datenschutzAccepted')")
            v-btn(color="primary" @click="save" :loading="loading > 0")
              template(v-if="isVoranmeldung") Weiter
              template(v-else-if="warteliste") Auf Warteliste Eintragen
              template(v-else) Anmeldung

  v-progress-linear(:active="$apollo.queries.termine.loading" indeterminate)

  .d-flexx(v-if="!success")
    v-spacer
    div
      v-btn(exact v-if="termin || warteliste" @click="queryFilterUpdates({ anmeldungTermin: null, anmeldungWarteliste: null }, { selector: '#extra-termine-top' }, { push: true, keepPage: true })") Zurück zu den Terminen
      v-btn(exact v-else-if="ort && !nurEinOrtJahr" @click="refetchTermine" :to="{ query: {}, params: { scrollTo: { selector: '#extra-termine-top' } } }") Zurück zu den Feierorten

</template>

<style lang="stylus" scoped>
.extra-termine
  >>> label
    font-weight normal

</style>



<script>
import graphqlErrorObject from '../../../backend/mixins/graphql-error-object.js'
import queryFilter from '../../../backend/mixins/query-filter.js'
import gql from 'gql-id.macro'
import moment from 'moment'
import { sortBy, groupBy,  uniqBy } from 'lodash'

export default {
  props: {
    data: Object
  },
  mixins: [graphqlErrorObject, queryFilter],
  data() {
    return {
      fixme: false, // FIXME don't require login
      edit: {
        email: '',
        emailConfirmation: '',
        password: '',
        passwordConfirmation: '',
        telefon: '',
        plaetze: '',
        selbeKlasse: null,
        schuleId: null,
        gruppenname: '',
        photoAccepted: false,
        agbAccepted: false,
        datenschutzAccepted: false,
        newsletterAccepted: false,
        einzelanmeldung: null
      },
      selectedTage: [],
      success: false,
      loading: 0,
      termine: []
    }
  },
  apollo: {
    termine: {
      query: gql`query extraTermine($regionJahr: [String!]) {
        termine(q: "{\"sorts\":[\"zeit\",\"ort_name\"],\"sichtbare\":true,\"kommende\":true}", where: {kategorieIn: $regionJahr}, role: ANON) {
          id
          zeit
          jahr
          tnFreiDisplay
          tnFreiDisplayMehr
          tnReserviertDisplay
          anmeldungGeschlossen
          anmeldungOffen
          anmeldeschlussInfoWithDefault
          infotextWithDefault
          zeigeWarteliste
          zeigeWartelisteAb
          voranmeldung
          ort {
            id
            name
            adverbial
            kuerzel
            region
          }
        }
      }`,
      variables() {
        return {
          regionJahr: this.data && this.data.regionjahr
        }
      },
      skip() {
        return !this.data
      }
    },
    alleFeiernReserviertText: {
      query: gql`query extraTermineVueReserviertText {
        alleFeiernReserviertText
      }`
    },
    currentUser: {
      query: gql`query extraTermineVueUser {
        currentUser {
          id
          email
        }
      }`,
      skip() {
        return !this.loggedIn
      }
    }
  },
  watch: {
    regionen(regionen) {
      if (regionen.length === 1 && regionen[0].orte.length === 1 && regionen[0].orte[0].jahre.length === 1) {
        const ortTermine = regionen[0].orte[0]
        this.queryFilterUpdates({ ort: ortTermine.ort.id, jahr: ortTermine.jahre[0].jahr }, false, { keepPage: true })
      }
    },
    'edit.einzelanmeldung'(einzelanmeldung) {
      if (einzelanmeldung) {
        this.edit.selbeKlasse = true
      } else {
        this.edit.selbeKlasse = null
      }
    }
  },
  computed: {
    zeigeWarteliste() {
      return this.ortTermineWlVa.length > 0
    },
    nurEinOrtJahr() {
      const regionen = this.regionen
      return regionen.length === 1 && regionen[0].orte.length === 1 && regionen[0].orte[0].jahre.length === 1
    },
    regionen() {
      const allTermine = this.termine
      let regionen = groupBy(allTermine, (t) => t.ort.region || t.ort.kuerzel)
      regionen = Object.entries(regionen).map(([region, termine]) => ({ region, termine }))
      regionen = sortBy(regionen, 'name')
      const alleOrte = {}
      allTermine.forEach((t) => {
        alleOrte[t.ort.id] = t.ort
      })
      regionen.forEach((region) => {
        let orte = groupBy(region.termine, (r) => r.ort.id)
        orte = Object.entries(orte).map(([ortId, termine]) => ({ ort: alleOrte[ortId], termine }))
        orte = sortBy(orte, 'name')
        orte.forEach((ort) => {
          let jahre = groupBy(ort.termine, (o) => o.jahr)
          jahre = Object.entries(jahre).map(([jahr, termine]) => ({ jahr, termine }))
          jahre = sortBy(jahre, 'name')
          ort.jahre = jahre
          ort.termine = sortBy(ort.termine, 'zeit')
        })
        region.orte = orte
      })
      return regionen
    },
    ort() {
      const id = this.$route.query.ort
      return this.termine.map((t) => t.ort).find((o) => o.id == id)
    },
    ortTermine() {
      const ortId = this.$route.query.ort
      const jahr = this.$route.query.jahr
      if (ortId && jahr) {
        return this.termine.filter((t) => t.jahr == jahr && t.ort.id == ortId)
      } else {
        return []
      }
    },
    ortTermineWlVa() {
      return this.ortTermine.filter((t) => (t.anmeldungOffen && (t.zeigeWarteliste || t.voranmeldung)))
    },
    ortTage() {
      return uniqBy(this.ortTermineWlVa.map((termin) => ({ text: this.strftime(termin.zeit, 'dddd, D. MMM Y'), value: this.strftime(termin.zeit, 'Y-MM-DD') })), 'value')
    },
    isVoranmeldung() {
      return this.ortTermine.some((t) => t.voranmeldung && t.anmeldungOffen)
    },
    selectedTermine() {
      const tage = this.selectedTage
      return this.ortTermineWlVa.filter((t) => tage.indexOf(this.strftime(t.zeit, 'Y-MM-DD')) < 0).map((t) => t.id)
    },
    loggedIn() {
      return this.$store.state.loggedIn
    },
    showTerminliste() {
      return !this.$route.query.anmeldungTermin && !this.warteliste
    },
    art() {
      return this.$route.query.anmeldungArt 
    },
    warteliste() {
      return this.$route.query.anmeldungWarteliste
    },
    wartelisteJahr() {
      return (this.warteliste || '').replace(/^\d*_/, '') - 0 || null
    },
    termineWarteliste() {
      const wartelisteJahr = this.wartelisteJahr
      return this.termine.filter(termin => termin.zeigeWarteliste && termin.jahr === wartelisteJahr && (termin.tnFreiDisplay <= termin.zeigeWartelisteAb))
    },
    termineAnmelden() {
      const wartelisteJahr = this.wartelisteJahr
      return this.termine.filter(termin => termin.jahr === wartelisteJahr && termin.tnFreiDisplay > 0)
    },
    termineSelected() {
      const selected = this.wartelisteSelected || []
      return this.termine.filter(termin => selected.indexOf(termin.id) >= 0)
    },
    termin() {
      const id = this.terminId
      if (id) {
        return this.termine.find(t => `${t.id}` === id)
      }
    },
    terminId() {
      return this.$route.query.anmeldungTermin
    },
    wartelisteSelectedCleanJSON() {
      let ids = this.wartelisteSelected
      if (!this.$apollo.queries.termine.loading) {
        const termine = this.termineWarteliste
        ids = ids.filter(id => termine.find(termin => termin.id === id))
      }
      if (ids.length) {
        return JSON.stringify(ids)
      } else {
        return undefined
      }
    },
    wartelisteSelected: {
      get() {
        return JSON.parse(this.$route.query.anmeldungTermine || null) || []
      },
      set(value) {
        this.$router.replace({ params: { scrollTo: false }, query: {...this.$route.query, anmeldungTermine: JSON.stringify(value) } })
      }
    }
  },
  methods: {
    wartelisteStep(nr, termin) {
      if (termin) {
        return `${nr}_${termin.jahr}`
      } else if (this.warteliste) {
        return this.warteliste.replace(/^\d+_/, `${nr}_`)
      }
    },
    wartelisteIsStep(nr) {
      return new RegExp(`^${nr}_`).test(this.warteliste)
    },
    strftime(date, format) {
      if (date) {
        return moment(date).format(format)
      } else {
        return ''
      }
    },
    refetchTermine() {
      this.$apollo.queries.termine.refetch()
      this.gqlClearErrorsFor('userWithReservierungCreate')
    },
    save() {
      this.loading++
      this.gqlClearErrorsFor('userWithReservierungCreate')
      this.$apollo.mutate({
        mutation: gql`mutation ExtraTermineVue(
          $email: String,
          $emailConfirmation: String,
          $password: String,
          $passwordConfirmation: String,
          $telefon: String,
          $plaetze: String,
          
          $einzelanmeldung: Boolean,
          $selbeKlasse: Boolean,
          $schuleId: ID,
          $gruppenname: String,
          
          $termin: ID,
          $wartelistenTermine: [ID],
          $voranmeldung: Boolean,

          $photoAccepted: Boolean,
          $agbAccepted: Boolean,
          $datenschutzAccepted: Boolean,
          $newsletterAccepted: Boolean,
        ) {
          userWithReservierungCreate(
            email: $email,
            emailConfirmation: $emailConfirmation,
            password: $password,
            passwordConfirmation: $passwordConfirmation,
            telefon: $telefon,
            plaetze: $plaetze,

            einzelanmeldung: $einzelanmeldung,
            selbeKlasse: $selbeKlasse,
            schuleId: $schuleId,
            gruppenname: $gruppenname,

            termin: $termin,
            wartelistenTermine: $wartelistenTermine,
            voranmeldung: $voranmeldung,

            photoAccepted: $photoAccepted,
            agbAccepted: $agbAccepted,
            datenschutzAccepted: $datenschutzAccepted,
            newsletterAccepted: $newsletterAccepted,
          ) {
            success
          }
        }`,
        variables: {
          ...this.edit,
          termin: this.warteliste || this.isVoranmeldung ? null : this.termin.id,
          wartelistenTermine: this.warteliste || this.isVoranmeldung ? this.selectedTermine : null,
          voranmeldung: this.isVoranmeldung
        }
      }).then(response => {
        this.loading--
        if (response.data.userWithReservierungCreate.success) {
          this.success = true
          this.$vuetify.goTo(this.$refs.top)
        }
      }, err => {
        this.gqlAddErrors(err, false, ['userWithReservierungCreate', 'base'])
        this.$vuetify.goTo(this.$refs.top)
        this.loading--
      })
    }
  }
}
</script>
