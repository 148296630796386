import VCheckbox from 'vuetify/es5/components/VCheckbox'

export default {
  name: 'accept-newsletter',
  extends: VCheckbox,
  props: {
    label: {
      type: String,
      default() {
        return 'Ich bin damit einverstanden, über weitere Jugend-Angebote des HVD Berlin-Brandenburg KdöR informiert zu werden. Ich kann der Zusendung von E-Mails, die nicht der unmittelbaren Vertragsabwicklung dienen, jederzeit widersprechen.'
      }
    }
  }
}
