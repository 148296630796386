<template lang="pug">
v-container.extra-guest-ticket-booking(flex grid-list-md ref="top")
  template(v-if="$apollo.queries.user.loading")
    v-progress-linear(:active="true" indeterminate)
  template(v-else-if="successMsg")
    p.title Vielen Dank für Ihre Gästekartenbestellung für die JugendFEIER Ihres Kindes.
    p Ungefähr eine Woche nach Ablauf der Bestellfrist erhalten Sie von uns eine E-Mail-Bestätigung mit Rechnung zu Ihrer Gästekartenbestellung. Wir bitten Sie den Rechnungsbetrag fristgerecht zu bezahlen. Nur dann können wir Ihnen die Karten rechtzeitig per Post zusenden.
    p(v-if="successMsg.termin.ort.kuerzel == 'FSP'") Gästekartenwünsche die innerhalb der Bestellfrist bei uns eingegangen sind, können während der Bestellfrist und innerhalb von 2 Wochen ab Rechnungsversand korrigiert und unentgeltlich storniert werden. Danach ist eine Rücknahme und Rückerstattung von Gästekarten nicht mehr möglich. Dies gilt sowohl für nachbestellte Gästekarten als auch für Gästekarten von Teilnehmer_innen, die sich kurzfristig zur JugendFEIER anmelden.
    p(v-else) Gästekarten können bis zu 14 Tage vor der Festveranstaltung unentgeltlich storniert oder zurückgegeben werden. Es gilt das Datum des Poststempels. Danach ist eine Rücknahme und Rückerstattung von Gästekarten nicht mehr möglich. Dies gilt sowohl für nachbestellte Gästekarten als auch für Gästekarten von Teilnehmer_innen, die sich kurzfristig zur JugendFEIER anmelden.
    p Sollten Sie, neben Ihren bereits bestellten Karten, kurzfristig zusätzliche Eintrittskarten benötigen, rufen Sie uns an. Noch verfügbare Karten können Sie dann telefonisch reservieren und am Tag Ihrer Festveranstaltung an unserer Tageskasse abholen und vor Ort bezahlen.
  template(v-else-if="!user")
    p Daten laden fehlgeschlagen.
  template(v-else)
    template(v-if="gaestekarten.length === 0")
      p Sie haben noch keine Teilnehmer Gebucht und können daher keine Gästekarten bestellen
    template(v-else)
      v-alert.mb-3(:value="gqlHasErrorsFor('gaestekarteOrder')" dismissible @input="gqlClearErrorsFor('gaestekarteOrder')")
        .subheading Bitte überprüfen Sie ihre Eingaben.
        template(v-for="error in gqlErrorsFor('gaestekarteOrder', 'base')") {{error}}<br>
        template(v-for="error in gqlErrorsFor('gaestekarteOrder')") {{error}}<br>
      template(v-for="gaestekarte, i in gaestekarten")
        hr(v-if="i > 0")
        p JugendFEIER {{gaestekarte.termin.ort.adverbial}} {{gaestekarte.termin.ort.name}}, {{strftime(gaestekarte.termin.zeit, "dddd D. MMM Y H:mm [Uhr]")}}
        template(v-if="!gaestekarte.termin.hatGaestekartenbestellzeitraum")
          p Die Gästekarten können sie hier zu einem späteren Zeitpunkt bestellen.
        template(v-else-if="gaestekarte.termin.gaestekarteFrontendUpdateableInFuture")
          p Die Gästekarten können sie hier ab dem {{strftime(gaestekarte.termin.gestekartenbestellungAb, 'D.M.Y')}} bestellen.
        template(v-else)
          template(v-if="gaestekarte.teilnehmerGebuchtAnzahl === 0")
            p Sie haben keine Teilnehmer gebucht und können daher keine Gästekarten bestellen
          template(v-else)
            template(v-if="!gaestekarte.termin.gaestekarteFrontendUpdateable")
              p Die Online-Gästekartenbestellung für diesen Termin ist geschlossen.
              p: strong Gerne nehmen wir Ihre Bestellungen und Änderungswünsche ab sofort persönlich entgegen: per Mail unter {{constants && constants.infoMail}} oder telefonisch unter {{constants && constants.infoPhone}}.
            template(v-else)
              p: strong Bitte bestellen Sie bis zum {{strftime(gaestekarte.termin.gestekartenbestellungBis, 'D.M.Y')}} Ihre Gästekarten für die Festveranstaltung
              p Die Eintrittskarte Ihres Kindes bestellen Sie bitte NICHT mit, da diese bereits in der Teilnahmegebühr enthalten ist und Ihnen automatisch mit ihren bestellten Karten zugesandt wird. Bis zum {{strftime(gaestekarte.termin.gestekartenbestellungBis, 'D.M.Y')}} können Sie Ihre Bestellung jederzeit im unten stehenden Formular anpassen.
              p(v-if="gaestekarte.termin.garantierteGaestekarten")
                | Wir garantieren jeder Familie {{gaestekarte.termin.garantierteGaestekarten}} Eintrittskarten, wenn die Bestellung bis zum {{strftime(gaestekarte.termin.gestekartenbestellungBis, 'D.M.Y')}} bei uns eingeht. Selbstverständlich können Sie auch mehr Karten bestellen. Sollte die Platzkapazität jedoch insgesamt überschritten werden, melden wir uns bei Ihnen, um eine Kartenreduzierung vorzunehmen. Dies ist sehr selten erforderlich.
                template(v-if="gaestekarte.termin.ort.kuerzel == 'FSP'")
                  br
                  | Die Vergabe der Karten an die einzelnen Familien erfolgt in der Reihenfolge der Bestelleingänge in unserem Büro.
              p
                | Der Veranstaltungsort ist grundsätzlich barrierefrei, trotzdem sollten Sie uns über eventuelle Einschränkungen informieren, damit wir Sie und Ihre Gäste bestmöglich platzieren können. 
                template(v-if="gaestekarte.termin.ort.kuerzel == 'FSP'")  Da der Veranstaltungsort nur über eine begrenzte Anzahl von Rollstuhlplätzen verfügt, bitten wir Sie, diese nur in dringenden Fällen zu bestellen.
            v-layout(row wrap)
              template(v-if="editGkI === i && editGk")
                v-flex(xs12): v-checkbox(label="Ich brauche keine Gästekarten" v-model="braucheKeineGk" @click="editGk.erwachsene = '0'; editGk.kinder = '0'; editGk.streamer = '0'; editGk.mitglieder = '0'")
                template(v-if="!braucheKeineGk")
                  v-flex(xs12 :lg4="!hasStreamer(gaestekarte)" :lg3="hasStreamer(gaestekarte)"): v-text-field(v-model="editGk.erwachsene" label="Anzahl Erwachsene" persistent-hint :hint="`Eintrittskarte je ${gaestekarte.termin.ticketpreis.gast} €`" :error-messages="gqlErrorsFor('gaestekarteOrder', 'erwachsene')")
                  v-flex(xs12 :lg4="!hasStreamer(gaestekarte)" :lg3="hasStreamer(gaestekarte)"): v-text-field(v-model="editGk.kinder" label="Anzahl Kinder" persistent-hint :hint="`Eintrittskarte je ${gaestekarte.termin.ticketpreis.gastKind} €  (bis einschließlich 13 Jahre)`" :error-messages="gqlErrorsFor('gaestekarteOrder', 'kinder')")
                  v-flex(xs12 v-if="hasStreamer(gaestekarte)" :lg3="hasStreamer(gaestekarte)"): v-text-field(v-model="editGk.streamer" label="Anzahl Streamer_in" persistent-hint :hint="`Eintrittskarte je ${gaestekarte.termin.ticketpreis.streamer} €`" :error-messages="gqlErrorsFor('gaestekarteOrder', 'streamer')")
                  v-flex(xs12 :lg4="!hasStreamer(gaestekarte)" :lg3="hasStreamer(gaestekarte)"): v-text-field(v-model="editGk.mitglieder" label="Anzahl Mitglieder des HVD" persistent-hint hint="Eintrittskarte je 0 €" :error-messages="gqlErrorsFor('gaestekarteOrder', 'mitglieder')")
                v-flex(xs12).title.pt-3 Gesamt: <strong>{{preisGesamt(editGk)}} €</strong>
                template(v-for="mg,n in editGk.mgNamen")
                  v-flex(xs12 lg4): v-text-field(v-model="mg.vorname" :label="`MG-${n +1} Vorname`" :error-messages="gqlErrorsFor('gaestekarteOrder', 'mitgliederNamen', n, 'vorname')")
                  v-flex(xs12 lg4): v-text-field(v-model="mg.nachname" :label="`MG-${n +1} Nachname`" :error-messages="gqlErrorsFor('gaestekarteOrder', 'mitgliederNamen', n, 'nachname')")
                  v-flex(xs12 lg4): v-text-field(v-model="mg.mgNr" :label="`MG-${n +1} Mitglieds-Nr.`" :error-messages="gqlErrorsFor('gaestekarteOrder', 'mitgliederNamen', n, 'nr')")
                template(v-if="!braucheKeineGk")
                  v-flex(xs12): v-textarea(v-model="editGk.einschraenkungen" label="gfls. Einschränkungen der Gäste" rows="3" auto-grow :error-messages="gqlErrorsFor('gaestekarteOrder', 'einschraenkungen')")
                v-flex(xs12): v-textarea(v-model="editGk.anmerkungen" label="gfls. zusätzliche Anmerkungen" rows="3" auto-grow :error-messages="gqlErrorsFor('gaestekarteOrder', 'anmerkungen')")
                v-flex(xs12 v-if="gaestekarte.termin.ort.kuerzel !== 'FSP'")
                  | Lokale Zeitungen berichten regelmäßig über die JugendFEIERn. Meist wird ein Gruppenbild von allen Teilnehmern erstellt, entweder durch Pressefotografen oder durch die von uns beauftragten offiziellen Partner. Die Namen der auf diesen Gruppenbildern Abgebildeten werden bei uns angefragt.
                  radio-group(row v-model="editGk.nameAnPresse" :items="[{ text: 'Ja', value: true }, { text: 'Nein', value: false }]" label="Ich bin damit einverstanden, dass der HVD Berlin-Brandenburg KdöR den / die Namen meines Kindes / meiner Kinder an die Presse weitergibt." :error-messages="nameAnPresseError")
                v-flex(xs12)
                  v-btn(color="primary" @click="save(editGk)" :loading="loading > 0") {{braucheKeineGk ? 'Speichern' : 'Gästekarten Bestellen'}}
                  v-btn(@click="editGkI = null") Abbrechen
              template(v-else)
                v-flex(xs12 :lg4="!hasStreamer(gaestekarte)" :lg3="hasStreamer(gaestekarte)"): v-text-field(v-model="gaestekarte.erwachsene" label="Anzahl Erwachsene" persistent-hint :hint="`Eintrittskarte je ${gaestekarte.termin.ticketpreis.gast} €`" :disabled="true")
                v-flex(xs12 :lg4="!hasStreamer(gaestekarte)" :lg3="hasStreamer(gaestekarte)"): v-text-field(v-model="gaestekarte.kinder" label="Anzahl Kinder" persistent-hint :hint="`Eintrittskarte je ${gaestekarte.termin.ticketpreis.gastKind} €  (bis einschließlich 13 Jahre)`" :disabled="true")
                v-flex(xs12 v-if="hasStreamer(gaestekarte)" :lg3="hasStreamer(gaestekarte)"): v-text-field(v-model="gaestekarte.streamer" label="Anzahl Streamer_in" persistent-hint :hint="`Eintrittskarte je ${gaestekarte.termin.ticketpreis.streamer} €`" :disabled="true")
                v-flex(xs12 :lg4="!hasStreamer(gaestekarte)" :lg3="hasStreamer(gaestekarte)"): v-text-field(v-model="gaestekarte.mitglieder" label="Anzahl Mitglieder des HVD" persistent-hint hint="Eintrittskarte je 0 €" :disabled="true")
                v-flex(xs12).title.pt-3 Gesamt: <strong>{{preisGesamt(gaestekarte)}} €</strong>
                template(v-if="gaestekarte.mitglieder - 0 > 0")
                  template(v-for="mg,n in mgStrToArray(gaestekarte.mitgliederNamen)")
                    v-flex(xs12 lg4): v-text-field(:value="mg.vorname" :label="`MG-${n +1} Vorname`" disabled)
                    v-flex(xs12 lg4): v-text-field(:value="mg.nachname" :label="`MG-${n +1} Nachname`" disabled)
                    v-flex(xs12 lg4): v-text-field(:value="mg.mgNr" :label="`MG-${n +1} Mitglieds-Nr.`" disabled)
                v-flex(xs12): v-textarea(v-model="gaestekarte.einschraenkungen" label="gfls. Einschränkungen der Gäste" :disabled="true" rows="3" auto-grow)
                v-flex(xs12): v-textarea(v-model="gaestekarte.anmerkungen" label="gfls. zusätzliche Anmerkungen" :disabled="true" rows="3" auto-grow)
                v-flex(xs12 v-if="gaestekarte.termin.ort.kuerzel !== 'FSP'")
                  | Lokale Zeitungen berichten regelmäßig über die JugendFEIERn. Meist wird ein Gruppenbild von allen Teilnehmern erstellt, entweder durch Pressefotografen oder durch die von uns beauftragten offiziellen Partner. Die Namen der auf diesen Gruppenbildern Abgebildeten werden bei uns angefragt.
                  radio-group(row :value="gaestekarte.nameAnPresse" disabled :items="[{ text: 'Ja', value: true }, { text: 'Nein', value: false }]" label="Ich bin damit einverstanden, dass der HVD Berlin-Brandenburg KdöR den / die Namen meines Kindes / meiner Kinder an die Presse weitergibt.")
                v-flex(xs12 v-if="gaestekarte.termin.gaestekarteFrontendUpdateable && gaestekarte.teilnehmerGebuchtAnzahl > 0")
                  v-btn(color="primary" @click="editGkI = i") Gästekartenbestellung ändern
              v-flex(xs12).pt-3: div(v-if="gaestekarte.termin.ort.ruecktrittsbedingungenGk" v-html="gaestekarte.termin.ort.ruecktrittsbedingungenGk")

</template>

<style lang="stylus" scoped>
.extra-guest-ticket-booking
  >>> label
    font-weight normal

</style>

<script>
import graphqlErrorObject from '../../../backend/mixins/graphql-error-object.js'
import gql from 'gql-id.macro'
import moment from 'moment'

export default {
  props: {
    data: Object
  },
  mixins: [graphqlErrorObject],
  data() {
    return {
      editGk: null,
      editGkI: null,
      successMsg: null,
      braucheKeineGk: null,
      nameAnPresseError: [],
      loading: 0,
    }
  },
  apollo: {
    user: {
      query: gql`query extraGuestTicketBookingVueUser {
        user: currentUser {
          id
          gaestekarten {
            id
            erwachsene
            kinder
            streamer
            mitglieder
            mitgliederNamen
            einschraenkungen
            anmerkungen
            nameAnPresse
            teilnehmerGebuchtAnzahl
            hatKartenBestellt
            hatBestellungAbgegeben
            termin {
              id
              zeit
              garantierteGaestekarten
              gestekartenbestellungAb
              gestekartenbestellungBis
              hatGaestekartenbestellzeitraum
              gaestekarteFrontendUpdateable
              gaestekarteFrontendUpdateableInFuture
              gaestekartenBestallungHatBegonnen
              ticketpreis {
                id
                gast
                gastKind
                streamer
              }
              ort {
                id
                adverbial
                name
                kuerzel
                ruecktrittsbedingungenGk
              }
            }
          }
          teilnehmer {
            id
            status
            termin {
              id
              zeit
              garantierteGaestekarten
              gestekartenbestellungAb
              gestekartenbestellungBis
              hatGaestekartenbestellzeitraum
              gaestekarteFrontendUpdateable
              gaestekarteFrontendUpdateableInFuture
              gaestekartenBestallungHatBegonnen
              ticketpreis {
                id
                gast
                gastKind
                streamer
              }
              ort {
                id
                adverbial
                name
                kuerzel
                ruecktrittsbedingungenGk
              }
            }
          }
        }
      }`
    },
    constants: {
      query: gql`query extraGuestTicketBookingVueConstants {
        constants {
          infoMail
          infoPhone
        }
      }`
    }
  },
  watch: {
    gaestekarten(gks) {
      const i = gks.findIndex(g => g.teilnehmerGebuchtAnzahl > 0 && !g.hatBestellungAbgegeben && g.termin.gaestekarteFrontendUpdateable)
      if (i >= 0) {
        this.editGkI = i
      }
    },
    editGkI(gki) {
      const gk = this.gaestekarten[gki]
      if (gk) {
        this.braucheKeineGk = gk.hatBestellungAbgegeben && gk.erwachsene === "0" && gk.kinder === "0" && gk.mitglieder === "0" && gk.streamer === "0"
        this.editGk = {
          ...gk,
          mgNamen: this.mgStrToArray(gk.mitgliederNamen)
        }
      }
    },
    'editGk.mitglieder'(mitglieder) {
      const mgNamen = this.editGk.mgNamen
      while (mitglieder - 0 > mgNamen.length && mgNamen.length < 30) {
        mgNamen.push({ vorname: '', nachname: '', mgNr: '' })
      }
      while (mitglieder - 0 < this.editGk.mgNamen.length) {
        mgNamen.pop()
      }
    }
  },
  computed: {
    gaestekarten() {
      const user = this.user
      if (!user) {
        return []
      }
      const termine = []
      user.teilnehmer.forEach(tn => {
        if (!termine.some(t => t.id == tn.termin.id)) {
          termine.push(tn.termin)
        }
      })
      const gaestekarten = user.gaestekarten.map(g => ({...g, erwachsene: `${g.erwachsene}`, kinder: `${g.kinder}`, streamer: `${g.streamer}`, mitglieder: `${g.mitglieder}`}))
      const neueGaestekarten = termine.filter(t => !user.gaestekarten.some(gk => gk.termin.id == t.id))
        .map(t => {
          return {
            id: null,
            terminId: t.id,
            termin: t,
            erwachsene: '',
            kinder: '',
            streamer: '',
            mitglieder: '',
            mitgliederNamen: '',
            einschraenkungen: '',
            anmerkungen: '',
            nameAnPresse: null,
            teilnehmerGebuchtAnzahl: user.teilnehmer.filter(tn => tn.termin.id === t.id && tn.status === "Gebucht").length
          }
        })
      return [].concat(gaestekarten, neueGaestekarten)
    },
    neueWartelisten() {
      if (this.user) {
        return this.user.wartelisten.filter(w => w.status === 'Neu')
      } else {
        return []
      }
    },
    gruppe() {
      if (this.user) {
        return this.user.gruppen.filter(g => g.termin).reverse()[0]
      }
    },
    termin() {
      if (this.gruppe) {
        return this.gruppe.termin
      } else if (this.user) {
        const tn = this.user.teilnehmer.reverse().map(tn => tn.termin)
        return tn.filter(t => t)[0]
      }
    },
    reservedKinder() {
      if (!this.user) {
        return 0
      }
      return this.user.teilnehmer.filter(t => !t.komplett).length
    }
  },
  methods: {
    hasStreamer(gk) {
      return !!(gk && gk.termin.ticketpreis.streamer)
    },
    mgStrToArray(mitgliederNamen, count = null) {
      const mgs = []
      if (!mitgliederNamen) {
        return mgs
      }
      ;(mitgliederNamen||'').split("\n").forEach(line => {
        const parts = line.split(",")
        mgs.push({
          vorname: parts.shift() || '',
          nachname: parts.shift() || '',
          mgNr: parts.join(" ") || ''
        })
      })
      if (count !== null) {
        while (mgs.length < count) {
          mgs.push({ vorname: '', nachname: '', mgNr: '' })
        }
        while (mgs.length > count) {
          mgs.pop()
        }
      }
      return mgs
    },
    mgArrayToStr(mgNamen) {
      return mgNamen.map(mg => `${mg.vorname.replace(/,/, ' ')},${mg.nachname.replace(/,/, ' ')},${mg.mgNr.replace(/,/, ' ')}`.replace(/[, ]+$/, '')).join("\n")
    },
    preisGesamt(gaestekarte) {
      const gast = (gaestekarte.erwachsene - 0) * gaestekarte.termin.ticketpreis.gast || 0
      const kind = (gaestekarte.kinder - 0) * gaestekarte.termin.ticketpreis.gastKind || 0
      const streamer = (gaestekarte.streamer - 0) * gaestekarte.termin.ticketpreis.streamer || 0
      return gast + kind + streamer
    },
    gesamtAnzahl(gaestekarte) {
      return (gaestekarte.erwachsene - 0) + (gaestekarte.kinder - 0) + (gaestekarte.streamer - 0) + (gaestekarte.mitglieder - 0)
    },
    strftime(date, format) {
      if (date) {
        return moment(date).format(format)
      } else {
        return ''
      }
    },
    save(gaestekarte) {
      if (gaestekarte.termin.ort.kuerzel !== 'FSP') {
        if (gaestekarte.nameAnPresse == null) {
          this.nameAnPresseError = ['muss angegeben werden']
          return
        }
      }
      this.nameAnPresseError = []
      this.loading++
      this.gqlClearErrorsFor('gaestekarteOrder')
      this.$apollo.mutate({
        mutation: gql`mutation extraGuestTicketBookingVueSave(
          $id: ID
          $terminId: ID
          $erwachsene: String
          $kinder: String
          $streamer: String
          $mitglieder: String
          $mitgliederNamen: String
          $einschraenkungen: String
          $anmerkungen: String
          $nameAnPresse: Boolean
        ) {
          gaestekarteOrder(
            id: $id
            terminId: $terminId
            erwachsene: $erwachsene
            kinder: $kinder
            streamer: $streamer
            mitglieder: $mitglieder
            mitgliederNamen: $mitgliederNamen
            einschraenkungen: $einschraenkungen
            anmerkungen: $anmerkungen
            nameAnPresse: $nameAnPresse
          ) {
            id
            erwachsene
            kinder
            streamer
            mitglieder
            mitgliederNamen
            einschraenkungen
            anmerkungen
            nameAnPresse
          }
        }`,
        variables: {
          ...gaestekarte,
          mitgliederNamen: this.mgArrayToStr(gaestekarte.mgNamen),
          mgNamen: undefined,
          termin: undefined,
        }
      }).then(response => {
        this.loading--
        this.editGkI = null
        this.successMsg = gaestekarte
        this.$vuetify.goTo(this.$refs.top)
      }, err => {
        this.gqlAddErrors(err, ['gaestekarteOrder', 'base'])
        this.$vuetify.goTo(this.$refs.top)
        this.loading--
      })
    }
  }
}
</script>
