import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import cookie from 'cookie'
import ActionCable from 'actioncable'
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink'
import introspectionQueryResultData from '../../backend/generated/fragment-types.json'


Vue.use(VueApollo)

const httpLink = new HttpLink({ uri: '/graphql' })
const middlewareLink = new ApolloLink((operation, forward) => {
  // This function is called before every request.
  const authenticityToken = cookie.parse(document.cookie).authenticity_token
  if (authenticityToken) {
    operation.setContext({
      credentials: 'include',
      headers: {
        'X-CSRF-Token': authenticityToken
      }
    })
  }
  return forward(operation)
})

const hasSubscriptionOperation = ({ query: { definitions } }) => {
  return definitions.some(
    ({ kind, operation }) => kind === 'OperationDefinition' && operation === 'subscription'
  )
}

const logoutLink = onError(({ networkError }) => {
  if (networkError && networkError.statusCode === 401) {
    if (window && window.location) {
      window.location = '/admin/login'
    }
  }
})

const cable = ActionCable.createConsumer()
const link1 = ApolloLink.split(
  hasSubscriptionOperation,
  new ActionCableLink({cable}),
  middlewareLink.concat(httpLink)
)

const operationAliasLink = function(operation, forward) {
  if (operation.operationName && /^GQLS_/.test(operation.operationName)) {
      operation.setContext({
        http: {
          includeQuery: false,
        }
      })
  }
  return forward(operation)
}

const link = ApolloLink.from([
  operationAliasLink,
  link1,
])
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})
const apolloClient = new ApolloClient({
  link: logoutLink.concat(link),
  cache: new InMemoryCache({fragmentMatcher}),
  connectToDevTools: true,
})

const apolloProvier = new VueApollo({ defaultClient: apolloClient })

export default apolloProvier
