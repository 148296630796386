import Vue from 'vue'
import Vuex from 'vuex'
import { bootstrap, optOut, optIn } from 'vue-gtag'
// import { admin } from "../../backend/utils/api"



const user = window.dataVueApp?.user || null
const gtagEnabledInitial = window.dataVueApp?.gtagId ? localStorage.getItem('gtagEnabled') || 'ask' : 'disabled'
let gtagBootstrapped = gtagEnabledInitial === 'enabled'

export const state = () => ({
  loggedIn: document.body.classList.contains('logged-in'), // FIXME remove with frontend v1
  user,
  editPage_: JSON.parse(localStorage.getItem('editPage') || 'false'),
  messages: [],
  gtagId: window.dataVueApp?.gtagId || null,
  gtagEnabled: gtagEnabledInitial,
})
let msgId = 0;
const msgTimeouts = {}

function message(message, options, store) {
  const msg = { ...options, ...message, id: ++msgId }
  if (msg.timeout > 0) {
    msgTimeouts[msg.id] = window.setTimeout(() => store.commit('removeMsg', msg.id), msg.timeout)
  }
  return msg
}

export const mutations = {
  SET_GTAG_(state, enabled) {
    state.gtagEnabled = enabled
    localStorage.setItem('gtagEnabled', enabled)
  },
  TOGGLE_EDIT_PAGE(state) {
    state.editPage_ = !state.editPage_
    localStorage.setItem('editPage', JSON.stringify(state.editPage_ || false))
  },
  addError(state, msg) {
    state.messages.push(message(msg, { type: "error" }, this))
  },
  addSuccess(state, msg) {
    state.messages.push(message(msg, { type: "success" }, this))
  },
  clearMsgs(state) {
    if (state.messages.length) {
      state.messages = []
      Object.entries(msgTimeouts).forEach((msg) => {
        clearTimeout(msg[1])
        delete msgTimeouts[msg[0]]
      })
    }
  },
  removeMsg(state, msgId) {
    const i = state.messages.findIndex((m) => m.id == msgId)
    if (i >= 0) {
      const msg = state.messages.splice(i, 1)[0]
      const timeout = msgTimeouts[msg?.id]
      if (timeout) {
        clearTimeout(timeout)
        delete msgTimeouts[msg.id]
      }
    }
  },
}

export const getters = {
  isLoggedIn(store) {
    return !!store?.user
  },
  isAdmin(store) {
    return !!(store?.user?.roles?.indexOf('admin') > -1)
  },
  isMitarbeiter(store, getters) {
    return !!(store?.user?.roles?.indexOf('mitarbeiter') > -1 || getters.isAdmin)
  },
  editPage(store, getters) {
    return !!(store.editPage_ && getters.isAdmin)
  },
}

export const actions = {
  SET_GTAG(context, enabled) {
    context.commit('SET_GTAG_', enabled)
    if (enabled === 'enabled') {
      optIn()
      if (!gtagBootstrapped) {
        gtagBootstrapped = true
        bootstrap()
      }
    } else {
      optOut()
    }
  },
  async login(context, msg) {
    try {
      const to = msg.to
      delete msg.to
      await Vue.http.get("/admin/login?authenticity_token=refresh")
      await Vue.http.post("/admin/login", { user: msg })
      if (to) {
        window.location = to
      } else {
        window.location.reload()
      }
      // this.commit('addSuccess', { text: 'Login Erfolgreich', timeout: 4000 })
    } catch (err) {
      //   // this.commit('addError', { text: 'Login Fehlgeschlagen', timeout: 4000 })
      //   return err
      throw await err.json()
    }
  }
}

Vue.use(Vuex)
const store = new Vuex.Store({
  state,
  mutations,
  getters,
  actions,
})

export default store
