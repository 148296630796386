import Vue from 'vue'

import AcceptAgb from "./accept-agb"
Vue.component('accept-agb', AcceptAgb)
import AcceptDatenschutz from "./accept-datenschutz"
Vue.component('accept-datenschutz', AcceptDatenschutz)
import AcceptNewsletter from "./accept-newsletter"
Vue.component('accept-newsletter', AcceptNewsletter)
import AcceptFotos from "./accept-fotos"
Vue.component('accept-fotos', AcceptFotos)
import BekanntVon from "./bekannt-von"
Vue.component('bekannt-von', BekanntVon)

import InputDate from '../../backend/components/input-date.vue'
Vue.component('input-date', InputDate)
import ExtraGruppencode from './extra/extra-gruppencode.vue'
Vue.component('extra-gruppencode', ExtraGruppencode)
import ExtraGuestTicketBooking from './extra/extra-guest-ticket-booking.vue'
Vue.component('extra-guest-ticket-booking', ExtraGuestTicketBooking)
import ExtraMyDetails from './extra/extra-my-details.vue'
Vue.component('extra-my-details', ExtraMyDetails)
import ExtraTermine from './extra/extra-termine.vue'
Vue.component('extra-termine', ExtraTermine)
import ExtraVorbereitungsprogramm from './extra/extra-vorbereitungsprogramm.vue'
Vue.component('extra-vorbereitungsprogramm', ExtraVorbereitungsprogramm)
import RadioGroup from '../../backend/components/radio-group.vue'
Vue.component('radio-group', RadioGroup)
import SelectSchule from '../../backend/components/select-schule.vue'
Vue.component('select-schule', SelectSchule)
import FileInput from '../../backend/components/file-input.vue'
Vue.component('file-input', FileInput)
