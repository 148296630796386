<template lang="pug">
v-container.extra-my-details(flex grid-list-md ref="top")
  v-progress-linear(:active="$apollo.queries.user.loading" indeterminate)
  v-alert.mb-3(:value="!!successMsg" dismissible type="success" @input="successMsg = null") {{successMsg}}
  template(v-if="user && edit")
    template(v-if="vpUrls.length")
      span.title Hier geht es zum Vorbereitungsprogramm
      template(v-for="url in vpUrls")
        |  &#32;
        a(:href="url.url") {{url.name}}
      .mb-5

    v-alert.mb-3(:value="gqlHasErrorsFor('userWithTeilnehmerUpdate')" dismissible @input="gqlClearErrorsFor('userWithTeilnehmerUpdate')")
      .subheading Bitte überprüfen Sie ihre Eingaben.
      template(v-for="error in gqlErrorsFor('userWithTeilnehmerUpdate', 'base')") {{error}}<br>
    p(v-if="!user.komplett") Bitte ergänzen Sie die fehlenden Angaben, um Ihre Anmeldung abzuschließen.
    .mb-3.title Vertragsdaten
    v-layout(wrap)
      v-flex(xs12 sm6): v-text-field(label="E-Mail *" v-model="edit.email" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'email')" disabled)
      v-flex(xs12 sm6): v-text-field(label="Telefonnummer *" hint="(mobil/tagsüber)" persistent-hint v-model="edit.telefon" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'phone')" disabled)
      v-flex(xs12 sm6): v-text-field(label="Vorname (Vertragspartner)*" v-model="edit.vorname" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'first_name')" :disabled="user.komplett")
      v-flex(xs12 sm6): v-text-field(label="Nachname (Vertragspartner)*" v-model="edit.nachname" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'last_name')" :disabled="user.komplett")
      v-flex(xs12): v-text-field(label="Adresszusatz" v-model="edit.adresszusatz" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'adresszusatz')" :disabled="user.komplett" hint="(z. B. c/o, abweichender Name am Briefkasten oder andere zur Zustellung notwendige Angabe)" persistent-hint)
      v-flex(xs12 sm6): v-text-field(label="Straße *" v-model="edit.strasse" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'street')" :disabled="user.komplett")
      v-flex(xs12 sm6): v-text-field(label="Hausnummer *" v-model="edit.strasseNr" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'street_no')" :disabled="user.komplett")
      v-flex(xs12 sm6): v-text-field(label="PLZ *" v-model="edit.plz" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'zip')" :disabled="user.komplett")
      v-flex(xs12 sm6): v-text-field(label="Ort *" v-model="edit.ort" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'city')" :disabled="user.komplett")
      v-flex(xs12 v-if="termin && termin.ticketpreis.sozialRabatt")
        v-checkbox(label="Ich erhalte ALG II oder ich und / oder mein Kind besitze/n den Berlinpass und möchte/n den Sozialrabatt in Anspruch nehmen." v-model="edit.sozialRabatt" :disabled="user.komplett" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'sozialrabatt')")
        template(v-if="edit.sozialRabatt && (!user.komplett || !user.sozialrabattBestaetigt)")
          v-radio-group.mt-0(v-model="edit.sozialausweisKommt" v-if="!user.komplett || edit.sozialausweisKommt == 'later'" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'submit_sozialausweis')")
            v-radio.mb-0(label="Den entsprechenden Nachweis lade ich hoch." value="upload" v-if="!user.komplett")
            v-radio.mb-0(label="Den entsprechenden Nachweis sende ich Ihnen innerhalb von 7 Tagen per Post, Fax oder E-Mail." value="later" :disabled="user.komplett")
          p(v-if="edit.sozialausweisKommt === 'upload' && !user.komplett")
            file-input(v-model="edit.sozialausweisName" base64 @base64="edit.sozialausweisFile = $event" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'sozialausweisFile').concat(gqlErrorsFor('userWithTeilnehmerUpdate', 'sozialausweisFileName')).concat(gqlErrorsFor('userWithTeilnehmerUpdate', 'sozialausweis'))")
          p(v-if="edit.sozialausweisKommt === 'later'")
            | Postadresse: {{constants && constants.infoPost}}<br>
            | Fax: {{constants && constants.infoFax}}, E-Mail: {{constants && constants.infoMail}}

      v-flex(xs12 v-if="!user.acceptPhotoVideo"): accept-fotos(v-model="edit.acceptPhotoVideo" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'accept_photo_video')")
      v-flex(xs12 v-if="!user.acceptAgbs"): accept-agb(v-model="edit.acceptAgbs" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'accept_agbs')")
      v-flex(xs12 v-if="!user.acceptDatenschutz"): accept-datenschutz(v-model="edit.acceptDatenschutz" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'accept_datenschutz')")
      v-flex(xs12 v-if="!user.acceptPhotoVideo || !user.acceptAgbs"): accept-newsletter(v-model="edit.acceptInfoEmails" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'accept_info_emails')")
      v-flex(xs12 v-if="!user.hatBekanntVon && (!user.komplett || editTns.length)"): bekannt-von(v-model="edit.bekanntVon" :sonstiges.sync="edit.bekanntVonSonstiges" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'bekannt_von')")

    .my-3.title(v-if="termin") Angaben zu Ihrem Kind / Ihren Kindern
    v-layout(wrap)
      template(v-for="tns, editTn in [user.teilnehmer.filter(t => t.gebucht), editTns]")
        template(v-for="tn, tnI in tns")
          v-flex(xs12): strong  {{strftime(tn.termin.zeit, 'dddd D. MMM Y H:mm [Uhr]')}} {{tn.termin.ort.adverbial}} {{tn.termin.ort.name}}
          v-flex(xs12): .error--text {{!editTn ? '' : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'base').join(", ")}}
          v-flex(xs12 v-if="editTn")
            div Preis: {{tn.termin.preisDetailsAll}}
          v-flex(xs12 v-else)
            div Preis: {{tn.preisDetailsAll}}
          v-flex(xs12 v-if="tn.gruppe") Gruppe: {{tn.gruppe.jid}} {{tn.gruppe.vollerName}}
          v-flex(xs12 sm6): v-text-field(label="Vorname der_s Jugendlichen *" v-model="tn.vorname" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'teilnehmer_vorname')" :disabled="tn.gebucht")
          v-flex(xs12 sm6): v-text-field(label="Nachname der_s Jugendlichen *" v-model="tn.nachname" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'teilnehmer_nachname')" :disabled="tn.gebucht")
          v-flex(xs12 sm6): radio-group(label="Geschlecht *" row :items="geschlechter" v-model="tn.geschlecht" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'geschlecht')" :disabled="tn.gebucht")
          v-flex(xs12 sm6): input-date(label="Geburtstag *" v-model="tn.geburtstag" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'geburtstag')" :disabled="tn.gebucht")
          v-flex(xs12 sm6): v-text-field(label="Gfls. Behinderung" v-model="tn.behinderung" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'behinderung')" :disabled="tn.gebucht")
          v-flex(xs12 sm6): v-text-field(label="E-Mail-Adresse der_s Jugendlichen" v-model="tn.email" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'teilnehmer_email')" :disabled="tn.gebucht" hint="(Diese E-Mail-Adresse nutzen wir ausschließlich, um Ihr Kind auf Angebote im Vorbereitungsprogramm direkt aufmerksam machen zu können.)" persistent-hint)
          template(v-if="!tn.gruppe")
            template(v-if="tn.gebucht")
              v-flex(xs12 sm6): select-schule(label="Schule *" none other :value="tn.schule && tn.schule.id" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'school_id')" disabled)
              v-flex(xs12 sm6): v-text-field(:label="!tn.schule ? 'Schule und Klasse *' : 'Klasse *'" :value="tn.gruppenname" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'gruppenname')" disabled)
            template(v-else)
              v-flex(xs12 sm6): select-schule(label="Schule *" none other v-model="tn.schuleId" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'school_id')")
              v-flex(xs12 sm6): v-text-field(:label="tn.schuleId === '0' ? 'Schule und Klasse *' : 'Klasse *'" v-model="tn.gruppenname" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'gruppenname')" :disabled="tn.schuleId === '00'")
          v-flex(xs12): v-checkbox(label="Gegebenenfalls abweichende Adresse der_s Jugendliche_n" v-model="tn.hatAdresse" :disabled="tn.gebucht")
          template(v-if="tn.hatAdresse")
            v-flex(xs12): v-text-field(label="Adresszusatz" v-model="tn.adresszusatz" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'tn_adresszusatz')" :disabled="tn.gebucht"  hint="(z. B. c/o, abweichender Name am Briefkasten oder andere zur Zustellung notwendige Angabe)" persistent-hint)
            v-flex(xs12 sm6): v-text-field(label="Straße" v-model="tn.strasse" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'tn_strasse')" :disabled="tn.gebucht")
            v-flex(xs12 sm6): v-text-field(label="Hausnummer" v-model="tn.hausnummer" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'tn_hausnummer')" :disabled="tn.gebucht")
            v-flex(xs12 sm6): v-text-field(label="PLZ" v-model="tn.plz" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'tn_plz')" :disabled="tn.gebucht")
            v-flex(xs12 sm6): v-text-field(label="Ort" v-model="tn.ort" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'tn_ort')" :disabled="tn.gebucht")
          v-flex(xs12 v-if="tn.termin.ort.juhu"): v-checkbox(label="Juhu-Mitglied werden" v-model="tn.werdeJuHuMitglied" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'werde_hvd_mitglied')" :disabled="tn.gebucht"): div(slot="label")
            div(v-html="constants && constants.werdeJuhuHtml || 'werde JuHu (...Lade)'")
          v-flex(xs12 v-if="tn.buchungen.length")
            .title.mb-2
              | Im &#32;
              a(:href="vpUrlFor(tn).url") Vorbereitungsprogramm
              |  angemeldet in<br>
            template(v-for="buchung in tn.buchungen")
              v-chip(dark small :color="buchung.kurs.kategorie.color") {{buchung.kurs.kategorie.name}}
              v-chip(dark small :color="buchung.kurs.kategorie.color") {{buchung.kurs.kuerzel}}
              |  {{buchung.kurs.name}}{{buchung.warteliste ? " (auf Warteliste)" : ""}}<br>
          v-btn(@click="removeTeilnehmer(tnI)" v-if="!tn.gebucht")
            v-icon(left) remove
            | eigenes Kind
          v-flex(xs12): v-divider
    template(v-if="termin")
      div Zwillinge?
      v-btn( @click="addTeilnehmer(termin, gruppe)")
        v-icon(left) add
        | eigenes Kind
    p.mt-4(v-if="!user.komplett || editTns.length"): v-btn(color="primary" @click="save" :loading="loading > 0") Speichern

    template(v-if="neueWartelisten.length && !voranmeldung")
      .title(v-if="voranmeldung") Voranmeldung
      .title(v-else) Wartelisteneinträge
      template(v-for="warteliste in neueWartelisten")
        .mt-3: strong {{warteliste.anzahlTeilnehmer}} Plätze in
        template(v-for="termin in tage(warteliste.termine)")
          div {{termin.text}} {{termin.ort.adverbial}} {{termin.ort.name}}
    template(v-else)
      p.mt-5 An Ihren Vertragsdaten, den Daten Ihres_r Kinder hat sich etwas geändert? Bitte senden Sie uns eine Mail an <a :href="`mailto:${constants && constants.infoMail}`">{{constants && constants.infoMail}}</a> mit dem Stichwort „Datenänderung“ und der Teilnahmenummer Ihres Kindes / Ihrer Kinder im Betreff.

</template>

<style lang="stylus" scoped>
.extra-my-details
  >>> label
    font-weight normal

</style>

<script>
import graphqlErrorObject from '../../../backend/mixins/graphql-error-object.js'
import gql from 'gql-id.macro'
import moment from 'moment'
import { uniqBy } from 'lodash'

export default {
  props: {
    data: Object
  },
  mixins: [graphqlErrorObject],
  data() {
    return {
      edit: null,
      editTns: [],
      successMsg: null,
      loading: 0,
      termine: [],
      constants: null,
      geschlechter: [
        { text: 'Weiblich', value: 'female' },
        { text: 'Männlich', value: 'male' },
        { text: 'Divers', value: 'not_specified' },
      ],
    }
  },
  apollo: {
    user: {
      query: gql`query extraMyDetails {
        user: currentUser {
          id
          email
          nachname
          vorname
          telefon
          strasse
          strasseNr
          adresszusatz
          plz
          ort
          acceptAgbs
          acceptDatenschutz
          acceptPhotoVideo
          hatBekanntVon
          acceptInfoEmails
          sozialrabattBeantragt
          sozialrabattBestaetigt
          sozialausweisKommt
          komplett
          teilnehmer {
            id
            jahr
            jid
            komplett
            nachname
            vorname
            geschlecht
            geburtstag
            behinderung
            email
            werdeJuHuMitglied
            adresszusatz
            strasse
            hausnummer
            plz
            ort
            sozialrabattBeantragt
            sozialrabattBewilligt
            stornoPreise
            preis
            preisDetailsAll
            gebucht
            hatAdresse
            gruppenname
            schule {
              id
              name
            }
            gruppe {
              id
              jid
              vollerName
            }
            termin {
              id
              zeit
              fruehbucherDatum
              preisDetailsAll
              ticketpreis {
                id
                teilnehmer
                sozialRabatt
                fruehbucherRabatt
              }
              ort {
                id
                adverbial
                name
                juhu
                vpRegionen {
                  id
                  jahr
                  name
                  slug
                }
              }
            }
            buchungen {
              id
              warteliste
              kurs {
                id
                name
                kuerzel
                kategorie {
                  id
                  name
                  color
                }
              }
            }
          }
          gruppen {
            id
            jid
            jahr
            userErinnertUm
            vollerName
            name
            gruppenlink
            schule {
              id
            }
            user {
              id
              gruppen {
                id
                jahr
                gruppenlink
              }
            }
            termin {
              id
              zeit
              fruehbucherDatum
              preisDetailsAll
              ticketpreis {
                id
                teilnehmer
                sozialRabatt
                fruehbucherRabatt
              }
              ort {
                id
                adverbial
                name
              }
            }
            teilnehmer {
              id
            }
          }
          wartelisten {
            id
            status
            anzahlTeilnehmer
            voranmeldung
            termine {
              id
              zeit
              ort {
                id
                adverbial
                name
              }
            }
          }
        }
        constants {
          werdeJuhuHtml
          werdeJuhuDefault
          infoMail
          infoFax
          infoPost
        }
      }`,
      update(response) {
        this.constants = response.constants // must be loaded before termin data to ensure default option is honored
        return response.user
      }
    },
  },
  watch: {
    user(loadedUser) {
      const edit = {}
      const user = loadedUser || {}
      edit.email = user.email || ''
      edit.nachname = user.nachname || ''
      edit.vorname = user.vorname || ''
      edit.telefon = user.telefon || ''
      edit.strasse = user.strasse || ''
      edit.strasseNr = user.strasseNr || ''
      edit.adresszusatz = user.adresszusatz || ''
      edit.plz = user.plz || ''
      edit.ort = user.ort || ''
      edit.sozialRabatt = user.sozialrabattBeantragt || false
      edit.sozialausweisKommt = user.sozialausweisKommt || null
      edit.sozialrabattBestaetigt = user.sozialrabattBestaetigt || false
      edit.sozialausweisFileName = null
      edit.sozialausweisFile = null
      edit.acceptAgbs = user.acceptAgbs || false
      edit.acceptDatenschutz = user.acceptDatenschutz || false
      edit.acceptPhotoVideo = user.acceptPhotoVideo || false
      edit.acceptInfoEmails = user.acceptInfoEmails || false
      edit.bekanntVon = null
      edit.bekanntVonSonstiges = null
      this.edit = edit
    },
    termin(termin) {
      if (this.editTns.length === 0 && termin && !this.user.komplett && (this.reservedKinder > 0 || this.user.gruppen.length > 0)) {
        this.addTeilnehmer(termin, this.gruppe)
      }
    }
  },
  computed: {
    voranmeldung() {
      return this.neueWartelisten.some((w) => w.voranmeldung)
    },
    neueWartelisten() {
      if (this.user) {
        return this.user.wartelisten.filter(w => w.status === 'Neu')
      } else {
        return []
      }
    },
    gruppe() {
      if (this.user) {
        return this.user.gruppen.filter(g => g.termin).reverse()[0]
      }
    },
    termin() {
      if (this.gruppe) {
        return this.gruppe.termin
      } else if (this.user) {
        const tn = this.user.teilnehmer.reverse().map(tn => tn.termin)
        return tn.filter(t => t)[0]
      }
    },
    reservedKinder() {
      if (!this.user) {
        return 0
      }
      return this.user.teilnehmer.filter(t => !t.komplett).length
    },
    vpUrls() {
      return uniqBy(this.user.teilnehmer.filter(t => t.gebucht).map(this.vpUrlFor), ['url'])
    }
  },
  methods: {
    tage(termine) {
      return uniqBy(termine.map((termin) => ({ ort: termin.ort, text: this.strftime(termin.zeit, 'dddd, D. MMM Y'), value: this.strftime(termin.zeit, 'Y-MM-DD') })), 'value')
    },
    vpUrlFor(tn) {
      const region = tn.termin.ort.vpRegionen.find((r) => r.jahr === tn.jahr)
      if (region) {
        return { url: `/vorbereitungsprogramm/${region.slug}/${region.jahr}`, name: `${region.name} ${region.jahr}` }
      } else {
        return { url: null, name: null }
      }
    },
    addTeilnehmer(termin, gruppe) {
      this.editTns.push({
        terminId: termin && termin.id,
        termin: termin,
        gruppeId: gruppe && gruppe.id,
        gruppe: gruppe,
        vorname: '',
        nachname: '',
        geschlecht: null,
        geburtstag: null,
        behinderung: '',
        email: '',
        werdeJuHuMitglied: termin.ort.juhu ? this.constants.werdeJuhuDefault : null,
        adresszusatz: '',
        strasse: '',
        hausnummer: '',
        plz: '',
        ort: '',
        hatAdresse: false,
        schuleId: null,
        gruppenname: '',
        buchungen: []
      })
    },
    removeTeilnehmer(tnI) {
      this.editTns.splice(tnI, 1)
    },
    strftime(date, format) {
      if (date) {
        return moment(date).format(format)
      } else {
        return ''
      }
    },
    dateIsInFuture(date) {
      return new Date(date || '1970-1-1').getTime() > Date.now()
    },
    save() {
      this.loading++
      this.gqlClearErrorsFor('userWithTeilnehmerUpdate')
      this.$apollo.mutate({
        mutation: gql`mutation ExtraMyDetailsVue(
          $email: String,
          $nachname: String,
          $vorname: String,
          $telefon: String,
          $strasse: String,
          $strasseNr: String,
          $adresszusatz: String,
          $plz: String,
          $ort: String,
          $sozialRabatt: Boolean,
          $sozialausweisFileName: String,
          $sozialausweisFile: String,
          $sozialausweisKommt: String,
          $acceptAgbs: Boolean,
          $acceptDatenschutz: Boolean,
          $acceptPhotoVideo: Boolean,
          $acceptInfoEmails: Boolean,
          $bekanntVon: [String],
          $bekanntVonSonstiges: String,
          $teilnehmer: [UserWithTeilnehmerUpdateInput],
        ) {
          userWithTeilnehmerUpdate(
            email: $email,
            nachname: $nachname,
            vorname: $vorname,
            telefon: $telefon,
            strasse: $strasse,
            strasseNr: $strasseNr,
            adresszusatz: $adresszusatz,
            plz: $plz,
            ort: $ort,
            sozialRabatt: $sozialRabatt,
            sozialausweisFileName: $sozialausweisFileName,
            sozialausweisFile: $sozialausweisFile,
            sozialausweisKommt: $sozialausweisKommt,
            acceptAgbs: $acceptAgbs,
            acceptDatenschutz: $acceptDatenschutz,
            acceptPhotoVideo: $acceptPhotoVideo,
            acceptInfoEmails: $acceptInfoEmails,
            bekanntVon: $bekanntVon,
            bekanntVonSonstiges: $bekanntVonSonstiges,
            teilnehmer: $teilnehmer,
          ) {
            success
          }
        }`,
        variables: {
          ...this.edit,
          teilnehmer: this.editTns.map(tn => ({...tn, termin: undefined, gruppe: undefined, buchungen: undefined }))
        }
      }).then(response => {
        this.loading--
        if (response.data.userWithTeilnehmerUpdate.success) {
          this.$apollo.queries.user.refetch()
          this.editTns = []
          if (this.voranmeldung && this.editTns.length === 0) {
            this.successMsg = 'Wir bearbeiten Ihre Anmeldung schnellstmöglich, um Ihnen in Kürze einen Feiertermin mitteilen zu können. Sobald wir einen festen Termin vergeben können, erhalten Sie eine E-Mail mit allen weiteren Informationen'
          } else {
            this.successMsg = 'Speichern Erfolgreich'
          }
          this.$vuetify.goTo(this.$refs.top)
        }
      }, err => {
        window.e = this.graphqlErrorsObject
        this.gqlAddErrors(err, ['userWithTeilnehmerUpdate', 'base'])
        this.$vuetify.goTo(this.$refs.top)
        this.loading--
      })
    }
  }
}
</script>
