<template lang="pug">
div.mt-3
  p.subheading Foto- und Videoaufnahmen
  template(v-if="constants && constants.fotoAufnahmenTextHtml")
    div(v-html="constants.fotoAufnahmenTextHtml")
  template(v-else)
    p Während der Festveranstaltungen macht ein_e Fotograf_in von jedem_r Teilnehmenden ein Einzelfoto auf der Bühne. Außerdem können die Feiern filmisch dokumentiert werden. Im Anschluss kann jede beteiligte Familie die Einzelfotos und/oder gegebenenfalls die DVD zur Veranstaltung kaufen. Diese individuellen Foto- und Videoaufnahmen, die die offiziellen Partner des HVD Berlin-Brandenburg KdöR während der Veranstaltungen herstellen, werden ausschließlich den an dem jeweiligen Feiertermin teilnehmenden Familien zugänglich gemacht und nach Ende der jeweiligen JugendFEIER-Saison gelöscht.
    p
      | Daneben werden im Auftrag des HVD Berlin-Brandenburg KdöR Foto- und Videoaufnahmen von den Festveranstaltungen gemacht, die der Außendarstellung des HVD Berlin-Brandenburg KdöR und der Dokumentation seiner Jugendarbeit dienen.
      br
      | Auch in den fakultativ angebotenen Vorbereitungskursen zur JugendFEIER werden gelegentlich Foto- und Videoaufnahmen von Gruppenveranstaltungen gemacht. Diese dienen ebenfalls der Außendarstellung des HVD Berlin-Brandenburg KdöR und der Dokumentation seiner Jugendarbeit.
      br
      | Selbstverständlich achten wir dabei stets darauf, dass Würde und Ansehen Ihres Kindes / Ihrer Kinder gewahrt bleiben
      br
      | Bitte schreiben Sie uns, wenn Sie Fragen dazu haben: {{constants && constants.infoMail}}.
    p Mit der Anmeldung für die JugendFEIER erklären Sie sich damit einverstanden, dass Ihr Kind gegebenenfalls auf Aufnahmen zu sehen ist, die im Rahmen der Öffentlichkeitsarbeit des HVD verwendet werden. Bitte schreiben Sie uns, wenn Sie Fragen dazu haben: {{constants && constants.infoMail}}.
  v-checkbox(:input-value="value" @change="$emit('input', $event)" :disabled="disabled" :error-messages="errorMessages" :label="(constants && constants.fotoAufnahmenLabel) || label")

</template>

<script>
import gql from 'gql-id.macro'

export default {
  props: {
    value: Boolean,
    label: {
      type: String,
      default: "* Ich habe den Hinweis zu Foto- und Videoaufnahmen verstanden und bin damit einverstanden, dass mein Kind / meine Kinder gegebenenfalls auf Gruppenaufnahmen zu sehen ist / sind, die im Rahmen der Öffentlichkeitsarbeit des HVD Berlin-Brandenburg KdöR (Print, Online und Soziale Medien) verwendet werden. Ich bin zudem damit einverstanden, dass während der JugendFEIER Einzelfotos meines Kindes / meiner Kinder auf der Bühne sowie gegebenenfalls Filmaufnahmen von der gesamten Veranstaltung gemacht werden, auf denen gegebenenfalls mein Kind / meine Kinder zu sehen ist / sind und die im Anschluss von den Teilnehmenden käuflich erworben werden können."
    },
    disabled: Boolean,
    errorMessages: Array,
  },
  apollo: {
    constants: {
      query: gql`query acceptFotosVueConstants {
        constants {
          infoMail
          fotoAufnahmenLabel
          fotoAufnahmenTextHtml
        }
      }`
    },
  },
}
</script>
