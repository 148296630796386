<template lang="pug">
div.mt-3
  v-select(:input-value="value" multiple @change="$emit('input', $event)" :items="quellen" :disabled="disabled" :error-messages="errorMessages" :label="label")
  v-text-field(:input="sonstiges" @change="$emit('update:sonstiges', $event)" :disabled="disabled" label="Aufmerksam geworden über:" v-if="value && value.some((v) => v === 'Sonstiges')")

</template>

<script>
export default {
  props: {
    value: Array,
    sonstiges: String,
    label: {
      type: String,
      default: "* Wie sind Sie auf uns aufmerksam geworden?"
    },
    disabled: Boolean,
    errorMessages: Array,
  },
  data() {
    return {
      quellen: [
        'Info- & Anmeldematerial über die Schule',
        'Radio',
        'Google',
        'Social Media (Facebook/Instagram)',
        'Freunde/Bekannte',
        'Ich war Gast bei einer JugendFEIER',
        'Sonstiges'
      ]
    }
  },
}
</script>
